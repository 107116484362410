@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Noto+Serif+JP:500&display=swap&subset=japanese");
@import url("https://fonts.googleapis.com/css?family=Lato|Open+Sans:400,600,700&display=swap");
* {
  margin: 0;
  padding: 0;
  font-size: 100%;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
}

img {
  border: 0;
}

ul, ol {
  list-style-type: none;
  text-indent: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

caption, th {
  font-style: normal;
  font-weight: normal;
  text-align: left;
}

em, strong {
  font-style: normal;
  font-weight: normal;
}

cite {
  font-style: normal;
}

input[type="text"] {
  padding: 0;
  border: none;
  border-radius: 0;
  outline: none;
  background: none;
}

textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  resize: none;
  padding: 0;
  border: 0;
  outline: none;
  background: transparent;
}

input[type="submit"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0;
  border: none;
  outline: none;
  background: transparent;
}

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: normal;
}

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Bold");
  font-weight: bold;
}

body {
  font-family: "游ゴシック", "Yu Gothic", "游ゴシック体", "YuGothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Roboto, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

* {
  box-sizing: border-box;
}

a {
  color: #000000;
}

/* PC */
@media screen and (min-width: 768px) {
  .forsp {
    display: none;
  }
  /*----------------------------------
共通レイアウト
-----------------------------------*/
  body {
    background-color: #ffffff;
    color: #282828;
    font-size: 16px;
    min-width: 960px;
    width: 100%;
    letter-spacing: 0.075em;
  }
  .inner {
    width: 100%;
    max-width: 1100px;
    min-width: 960px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 20px;
    padding-right: 20px;
  }
  img {
    width: 100%;
    vertical-align: bottom;
  }
  a {
    transition: all 0.2s linear;
    color: #61b6b1;
    text-decoration: none;
  }
  a:hover {
    opacity: 0.6;
  }
  .main {
    padding: 40px 0;
  }
  header {
    display: flex;
    align-items: start;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    min-width: 960px;
    z-index: 999;
  }
  header .header__logo {
    width: 280px;
    margin-left: 25px;
    margin-top: 10px;
  }
  header .header__info {
    display: flex;
    align-items: center;
  }
  header .header__info__tel {
    margin-right: 87px;
    margin-top: 15px;
  }
  header .header__info__tel a {
    text-decoration: none;
    color: #282828;
    font-size: 30px;
    font-family: 'Noto Serif JP', serif;
    font-weight: 500;
    line-height: 40px;
    background-image: url("images/ico_tel.svg");
    background-repeat: no-repeat;
    background-size: 26px;
    background-position: bottom left;
    padding-left: 34px;
  }
  header .header__info__btn {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 999;
  }
  header .header__info__btn a {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #61b6b1;
    width: 64px;
    height: 64px;
    position: relative;
  }
  header .header__info__btn a span {
    display: block;
    width: 24px;
    height: 4px;
    border-radius: 2px;
    background-color: #ffffff;
    transition: all 0.2s linear;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, 0);
  }
  header .header__info__btn a span:nth-child(1) {
    margin-top: -8px;
  }
  header .header__info__btn a span:nth-child(3) {
    margin-top: 8px;
  }
  header .header__info__btn a .header__info__btn__text {
    position: absolute;
    top: 47px;
    left: 50%;
    transform: translateX(-50%);
    width: 33px;
    height: 10px;
  }
  header .header__info__btn a.on span {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: 0px;
  }
  header .header__info__btn a.on span:nth-child(1) {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  header .header__info__btn a.on span:nth-child(3) {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
  header .header__info__btn a.on span:nth-child(2) {
    display: none;
  }
  header .header__info__btn a.on .header__info__btn__text {
    display: none;
  }
  header nav.gnav {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #61b6b1;
    width: 100%;
    height: 100%;
    text-align: center;
    font-family: 'Noto Serif JP', serif;
    font-weight: 500;
    font-size: 24px;
  }
  header nav.gnav.on ul {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
  header nav.gnav ul {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(2);
    transition: all 0.2s linear;
    opacity: 0;
  }
  header nav.gnav li {
    margin: 20px 0;
  }
  header nav.gnav a {
    color: #ffffff;
  }
  header nav.header__mobileBanner {
    display: none;
  }
  footer {
    background-color: #e5f5f4;
    padding: 40px 0;
  }
  footer .footer__gnav ul {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    line-height: 1.2;
  }
  footer .footer__gnav ul li a {
    color: #282828;
    display: block;
    padding: 0 16px;
    border-left: 1px solid #61b6b1;
  }
  footer .footer__gnav ul li:last-child a {
    border-right: 1px solid #61b6b1;
  }
  footer .footer__copyright {
    text-align: center;
    font-size: 12px;
    margin-top: 30px;
  }
  footer .footer__copyright .footer__copyright__logo img {
    width: 164px;
  }
  /*----------------------------------
パーツ
-----------------------------------*/
  a.btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 310px;
    height: 50px;
    position: relative;
    background-color: #61b6b1;
    margin: 0 auto;
    color: #ffffff;
    letter-spacing: 0;
  }
  a.btn .btn__arrow {
    display: block;
    position: absolute;
    width: 6px;
    height: 10px;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    line-height: 1;
  }
  a.btn .btn__arrow svg {
    width: 100%;
    height: 100%;
    vertical-align: top;
  }
  a.btn .btn__arrow svg path {
    fill: #ffffff;
  }
  a.btn:hover {
    box-shadow: 0 0 0 3px #61b6b1 inset;
    background-color: #ffffff;
    color: #61b6b1;
  }
  a.btn:hover svg path {
    fill: #61b6b1;
  }
  a.btn.btn--white {
    background-color: #ffffff;
    color: #00895f;
  }
  a.btn.btn--white svg path {
    fill: #00895f;
  }
  a.btn.btn--white:hover {
    box-shadow: 0 0 0 3px #ffffff inset;
    background-color: #00895f;
    color: #ffffff;
  }
  a.btn.btn--white:hover svg path {
    fill: #ffffff;
  }
  body.home {
    /* 
----------
HOME
----------
*/
  }
  body.home section {
    padding: 90px 0;
  }
  body.home .inner--full {
    max-width: 100%;
    padding-left: 70px;
    padding-right: 70px;
  }
  body.home h2 {
    text-align: center;
    margin-bottom: 40px;
  }
  body.home h2 span {
    display: block;
  }
  body.home h2 .head__title__en {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    line-height: 1;
    letter-spacing: 0.2em;
    color: #00895f;
    font-family: 'Noto Serif JP', serif;
    font-weight: 500;
    margin-bottom: 18px;
  }
  body.home h2 .head__title__en:before, body.home h2 .head__title__en:after {
    content: '';
    width: 72px;
    height: 1px;
    background-color: #00895f;
    margin: 0 12px;
  }
  body.home h2 .head__title__ja {
    font-size: 14px;
  }
  body.home .kv {
    width: 100%;
    height: 100vh;
    background-image: url("images/kv_pc.jpg");
    position: relative;
    background-size: cover;
    padding: 0;
  }
  body.home .kv h1 {
    position: absolute;
    top: 50%;
    left: 4.375vw;
    transform: translateY(-50%);
    width: 680px;
  }
  body.home .kv .kv__advantage__wrapper {
    position: absolute;
    bottom: 56px;
    left: 0;
    width: 100%;
  }
  body.home .kv .kv__advantage__wrapper .kv__advantage {
    max-width: 1100px;
    min-width: 960px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  body.home .kv .kv__advantage__wrapper .kv__advantage li {
    width: 23.58491%;
    height: 90px;
    display: flex;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8);
  }
  body.home .kv .kv__advantage__wrapper .kv__advantage li .kv__advantage__image {
    width: 29.6%;
    text-align: center;
  }
  body.home .kv .kv__advantage__wrapper .kv__advantage li .kv__advantage__image img {
    width: 75.67568%;
  }
  body.home .kv .kv__advantage__wrapper .kv__advantage li .kv__advantage__text {
    width: 68%;
    font-size: 16px;
    line-height: 26px;
    font-weight: bold;
    letter-spacing: 0;
  }
}

@media screen and (min-width: 768px) and (min-width: 1100px) {
  body.home .kv .kv__advantage__wrapper .kv__advantage li .kv__advantage__text {
    font-size: 18px;
    line-height: 28px;
  }
}

@media screen and (min-width: 768px) {
  body.home .kv .kv__scroll {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 44px;
    transform: translate(-50%, 50%);
  }
  body.home .message .inner--message {
    display: flex;
  }
  body.home .message .message__image {
    width: 33.67925%;
    margin-right: 1.25%;
  }
  body.home .message .message__image img {
    transform: translate(-10px, -46px);
  }
  body.home .message .message__text {
    width: 64.71698%;
  }
  body.home .feature {
    background-color: #e5f5f4;
  }
  body.home .feature .feature__list .feature__list__item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 437px;
    background-repeat: no-repeat;
    color: #ffffff;
    padding: 0 30px;
  }
  body.home .feature .feature__list .feature__list__item:not(:first-child) {
    margin-top: 20px;
  }
  body.home .feature .feature__list .feature__list__item:nth-child(odd) {
    justify-content: flex-end;
  }
  body.home .feature .feature__list .feature__list__item.feature__list__item--01 {
    background-image: url("images/bg_feature_01.jpg");
  }
  body.home .feature .feature__list .feature__list__item.feature__list__item--02 {
    background-image: url("images/bg_feature_02.jpg");
  }
  body.home .feature .feature__list .feature__list__item.feature__list__item--02 .feature__list__item__inner {
    width: 700px;
  }
  body.home .feature .feature__list .feature__list__item.feature__list__item--03 {
    background-image: url("images/bg_feature_03.jpg");
  }
  body.home .feature .feature__list .feature__list__item.feature__list__item--04 {
    background-image: url("images/bg_feature_04.jpg");
  }
  body.home .feature .feature__list .feature__list__item.feature__list__item--05 {
    background-image: url("images/bg_feature_05.jpg");
  }
  body.home .feature .feature__list .feature__list__item .feature__list__item__inner {
    width: 640px;
  }
  body.home .feature .feature__list .feature__list__item .feature__list__item__inner h3 {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  body.home .feature .feature__list .feature__list__item .feature__list__item__inner h3 .feature__head__no {
    display: block;
    width: 80px;
  }
  body.home .feature .feature__list .feature__list__item .feature__list__item__inner h3 .feature__head__text {
    font-family: 'Noto Serif JP', serif;
    font-weight: 500;
    display: block;
    font-size: 24px;
    line-height: 1.25em;
    margin-left: 20px;
  }
  body.home .feature .feature__list .feature__list__item .feature__content small {
    font-size: 12px;
  }
  body.home .trigger .trigger__content {
    background-image: url("images/bg_trigger_pc.jpg");
    background-position: center;
    background-size: cover;
    padding: 90px 0;
  }
  body.home .trigger .trigger__content .trigger__content__image {
    display: flex;
  }
  body.home .trigger .trigger__content .trigger__content__text h3 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 320px;
    height: 320px;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 160px;
    margin: 0 auto 24px;
    font-family: 'Noto Serif JP', serif;
    font-weight: 500;
    color: #00895f;
    font-size: 24px;
  }
  body.home .trigger .trigger__content .trigger__content__text h3 span:nth-child(2) {
    margin: 10px 0;
  }
  body.home .trigger .trigger__content .trigger__content__text .trigger__content__text__detail {
    background-color: #00895f;
    width: 584px;
    margin: 0 auto;
    padding: 36px;
    color: #ffffff;
  }
  body.home .menu {
    background-image: url("images/bg_menu.jpg");
    background-position: center;
    background-size: cover;
  }
  body.home .menu .menu__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: -5.09434%;
  }
  body.home .menu .menu__list li {
    width: 20.75472%;
    margin-bottom: 5.09434%;
    position: relative;
    box-shadow: 0px 0px 15.36px 0.64px rgba(10, 141, 99, 0.1);
  }
  body.home .menu .menu__list li img {
    vertical-align: bottom;
  }
  body.home .menu .menu__list li .menu__list__text {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgba(0, 137, 95, 0.3);
    text-align: center;
    color: #ffffff;
  }
  body.home .menu .menu__list li.menu__list__none {
    margin-bottom: 0;
  }
  body.home .topics .archive__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 30px;
  }
  body.home .topics .archive__list li {
    width: 23.69863%;
    background-color: #e5f5f4;
    padding: 30px 16px;
    border-radius: 4px;
  }
  body.home .topics .archive__list li a:hover {
    opacity: 1;
  }
  body.home .topics .archive__list li a:hover .archive__list__more {
    background-color: #00895f;
    color: #ffffff;
  }
  body.home .topics .archive__list li a:hover .archive__list__more svg path {
    fill: #ffffff;
  }
  body.home .topics .archive__list li .archive__list__date {
    font-size: 14px;
    color: #e63f3f;
    line-height: 1;
    margin-bottom: 12px;
  }
  body.home .topics .archive__list li .archive__list__title {
    font-size: 16px;
    color: #00895f;
    line-height: 1.71429em;
    margin-bottom: 12px;
    font-weight: bold;
  }
  body.home .topics .archive__list li .archive__list__more {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 106px;
    height: 30px;
    position: relative;
    background-color: #ffffff;
    margin: 0 auto;
    color: #00895f;
    transition: all 0.2s linear;
    border-radius: 15px;
    font-family: 'Noto Serif JP', serif;
    font-weight: 500;
    font-size: 12px;
  }
  body.home .topics .archive__list li .archive__list__more .archive__list__more__arrow {
    display: block;
    position: absolute;
    width: 6px;
    height: 10px;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    line-height: 1;
  }
  body.home .topics .archive__list li .archive__list__more .archive__list__more__arrow svg {
    width: 100%;
    height: 100%;
    vertical-align: top;
  }
  body.home .topics .archive__list li .archive__list__more .archive__list__more__arrow svg path {
    fill: #00895f;
  }
  body.home .about .about__info {
    display: flex;
  }
  body.home .about .about__info .about__info__text {
    width: 62.26415%;
  }
  body.home .about .about__info .about__info__text h3 {
    font-family: 'Noto Serif JP', serif;
    font-weight: 500;
    font-size: 30px;
  }
  body.home .about .about__info .about__info__text table {
    margin-top: 20px;
    border-collapse: collapse;
  }
  body.home .about .about__info .about__info__text table th, body.home .about .about__info .about__info__text table td {
    border-top: 1px solid #e5f5f4;
    padding: 1em;
    line-height: 1.5em;
  }
  body.home .about .about__info .about__info__text table th small, body.home .about .about__info .about__info__text table td small {
    font-size: 14px;
  }
  body.home .about .about__info .about__info__text table th {
    color: #61b6b1;
    font-weight: bold;
    text-align: center;
  }
  body.home .about .about__info .about__info__image {
    width: 35.09434%;
  }
  body.home .about .about__info .about__info__image img {
    margin-bottom: 16px;
  }
  body.home .about .about__map {
    margin-top: 10px;
  }
  body.home .blog {
    padding-top: 0;
  }
  body.home .blog .inner {
    position: relative;
  }
  body.home .blog .inner h3 {
    position: absolute;
    top: 50%;
    left: -20px;
    transform: translateY(-50%);
    background-color: #00895f;
    color: #ffffff;
    font-family: 'Noto Serif JP', serif;
    font-weight: 500;
    text-align: center;
    width: 414px;
    padding: 18px 0;
    font-size: 30px;
  }
  body.home .blog .inner h3 small {
    font-size: 18px;
    margin-left: 30px;
  }
  body.page h1, body.post h1, body.single h1, body.archive h1 {
    background-size: cover;
    background-position: center;
    height: 380px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  body.page h1 span, body.post h1 span, body.single h1 span, body.archive h1 span {
    display: block;
  }
  body.page h1 .pagetitle, body.post h1 .pagetitle, body.single h1 .pagetitle, body.archive h1 .pagetitle {
    color: #00895f;
    font-family: 'Noto Serif JP', serif;
    font-weight: 500;
    font-size: 48px;
    letter-spacing: 0.2em;
  }
  body.page h1 .pagetitle__sub, body.post h1 .pagetitle__sub, body.single h1 .pagetitle__sub, body.archive h1 .pagetitle__sub {
    font-size: 14px;
    letter-spacing: 0.2em;
  }
  body.page .path, body.post .path, body.single .path, body.archive .path {
    font-size: 12px;
    letter-spacing: -.40em;
    word-spacing: 0;
    padding: 20px 0;
  }
  body.page .path li, body.post .path li, body.single .path li, body.archive .path li {
    display: inline-block;
    letter-spacing: 0;
  }
  body.page .path li:not(:first-child), body.post .path li:not(:first-child), body.single .path li:not(:first-child), body.archive .path li:not(:first-child) {
    background-image: url("images/arrow.svg");
    background-size: 6px;
    background-repeat: no-repeat;
    background-position: center left;
    padding-left: 16px;
    margin-left: 10px;
  }
  body.archive {
    /* 
----------
アーカイブ
----------
*/
  }
  body.archive h1 {
    background-image: url("images/title_topics.jpg");
  }
  body.archive .main--archive {
    margin-bottom: 80px;
  }
  body.archive .archive__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 30px;
  }
  body.archive .archive__list li {
    width: 23.96226%;
    margin-bottom: 1.50943%;
    background-color: #e5f5f4;
    padding: 30px 16px;
    border-radius: 4px;
  }
  body.archive .archive__list li a:hover {
    opacity: 1;
  }
  body.archive .archive__list li a:hover .archive__list__more {
    background-color: #00895f;
    color: #ffffff;
  }
  body.archive .archive__list li a:hover .archive__list__more svg path {
    fill: #ffffff;
  }
  body.archive .archive__list li .archive__list__date {
    font-size: 14px;
    color: #e63f3f;
    line-height: 1;
    margin-bottom: 12px;
  }
  body.archive .archive__list li .archive__list__title {
    font-size: 16px;
    color: #00895f;
    line-height: 1.71429em;
    margin-bottom: 12px;
    font-weight: bold;
  }
  body.archive .archive__list li .archive__list__more {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 106px;
    height: 30px;
    position: relative;
    background-color: #ffffff;
    margin: 0 auto;
    color: #00895f;
    transition: all 0.2s linear;
    border-radius: 15px;
    font-family: 'Noto Serif JP', serif;
    font-weight: 500;
    font-size: 12px;
  }
  body.archive .archive__list li .archive__list__more .archive__list__more__arrow {
    display: block;
    position: absolute;
    width: 6px;
    height: 10px;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    line-height: 1;
  }
  body.archive .archive__list li .archive__list__more .archive__list__more__arrow svg {
    width: 100%;
    height: 100%;
    vertical-align: top;
  }
  body.archive .archive__list li .archive__list__more .archive__list__more__arrow svg path {
    fill: #00895f;
  }
  body.archive .wp-pagenavi {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  body.archive .wp-pagenavi .current, body.archive .wp-pagenavi .page {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    font-family: 'Noto Serif JP', serif;
    font-weight: 500;
    border: 1px solid #61b6b1;
    margin: 0 5px;
  }
  body.archive .wp-pagenavi .current {
    color: #61b6b1;
    background-color: #e5f5f4;
  }
  body.archive .wp-pagenavi .nextpostslink {
    font-family: 'Noto Serif JP', serif;
    font-weight: 500;
    position: relative;
    padding-right: 18px;
    margin-left: 10px;
  }
  body.archive .wp-pagenavi .nextpostslink:before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    z-index: 2;
    width: 6px;
    height: 10px;
    transform: translateY(-50%);
    background-image: url("images/arrow_green.svg");
    background-repeat: no-repeat;
    background-size: contain;
  }
  body.archive .wp-pagenavi .previouspostslink {
    font-family: 'Noto Serif JP', serif;
    font-weight: 500;
    position: relative;
    padding-left: 18px;
    margin-right: 10px;
  }
  body.archive .wp-pagenavi .previouspostslink:before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    z-index: 2;
    width: 6px;
    height: 10px;
    transform: translateY(-50%) scale(-1, 1);
    background-image: url("images/arrow_green.svg");
    background-repeat: no-repeat;
    background-size: contain;
  }
  body.post, body.single {
    /* 
----------
投稿
----------
*/
  }
  body.post h1, body.single h1 {
    background-image: url("images/title_topics.jpg");
  }
  body.post .main--single, body.single .main--single {
    display: flex;
    justify-content: space-between;
    margin-bottom: 80px;
  }
  body.post article.content, body.single article.content {
    width: 63.20755%;
  }
  body.post article.content .content__date, body.single article.content .content__date {
    font-size: 14px;
    color: #e63f3f;
    margin-bottom: 14px;
  }
  body.post article.content h2, body.single article.content h2 {
    font-size: 24px;
    line-height: 34px;
    color: #00895f;
    margin-bottom: 14px;
    font-weight: bold;
  }
  body.post article.content .content__categories ul li, body.single article.content .content__categories ul li {
    display: inline-block;
    color: #61b6b1;
    font-size: 14px;
    margin-right: 1em;
  }
  body.post article.content .content__categories ul li:before, body.single article.content .content__categories ul li:before {
    content: '#';
    display: inline;
  }
  body.post article.content .content__body, body.single article.content .content__body {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  body.post article.content .content__body p, body.single article.content .content__body p {
    margin: 1em 0;
  }
  body.post article.content .content__body ul, body.single article.content .content__body ul {
    margin: 1em 0 1em 1em;
  }
  body.post article.content .content__body ul li, body.single article.content .content__body ul li {
    padding-left: 1.5em;
    text-indent: -1.5em;
  }
  body.post article.content .content__body ul li:before, body.single article.content .content__body ul li:before {
    content: '•';
    display: inline-block;
    width: 1.5em;
    color: #61b6b1;
    text-indent: 0;
    text-align: center;
  }
  body.post article.content .content__body ol, body.single article.content .content__body ol {
    counter-reset: item;
    margin: 1em 0 1em 1em;
  }
  body.post article.content .content__body ol li, body.single article.content .content__body ol li {
    padding-left: 1.5em;
    text-indent: -1.5em;
  }
  body.post article.content .content__body ol li:before, body.single article.content .content__body ol li:before {
    counter-increment: item;
    content: counter(item) ".";
    color: #61b6b1;
    display: inline-block;
    width: 1.5em;
    text-indent: 0;
  }
  body.post article.content .content__body blockquote, body.single article.content .content__body blockquote {
    margin: 1em 0 1em 2em;
    padding-left: 1em;
    border-left: 6px solid #61b6b1;
  }
  body.post article.content .content__body table, body.single article.content .content__body table {
    border-collapse: collapse;
    margin: 1em 0;
  }
  body.post article.content .content__body table th, body.post article.content .content__body table td, body.single article.content .content__body table th, body.single article.content .content__body table td {
    border: 1px solid #61b6b1;
    padding: 10px;
  }
  body.post article.content .content__body table th, body.single article.content .content__body table th {
    background-color: #e5f5f4;
  }
  body.post aside, body.single aside {
    width: 32.07547%;
  }
  body.post aside h2, body.single aside h2 {
    background-color: #e5f5f4;
    padding: 14px;
    text-align: center;
  }
  body.post aside h2 span, body.single aside h2 span {
    display: block;
  }
  body.post aside h2 span.sidebar__title, body.single aside h2 span.sidebar__title {
    font-family: 'Noto Serif JP', serif;
    font-weight: 500;
    color: #00895f;
    font-size: 20px;
    line-height: 1.4;
  }
  body.post aside h2 span.sidebar__title__sub, body.single aside h2 span.sidebar__title__sub {
    font-family: 'Noto Serif JP', serif;
    font-weight: 500;
    color: #282828;
    font-size: 14px;
    line-height: 1.2;
  }
  body.post aside nav .sidebar__list li, body.single aside nav .sidebar__list li {
    padding: 26px 0;
    border-bottom: 1px solid #e5f5f4;
  }
  body.post aside nav .sidebar__list li .sidebar__list__date, body.single aside nav .sidebar__list li .sidebar__list__date {
    color: #e63f3f;
    font-size: 12px;
    margin-bottom: 10px;
  }
  body.post aside nav .sidebar__list li .sidebar__list__title, body.single aside nav .sidebar__list li .sidebar__list__title {
    font-size: 14px;
    font-weight: bold;
    line-height: 24px;
  }
  body.post aside nav .sidebar__list li .sidebar__list__title a, body.single aside nav .sidebar__list li .sidebar__list__title a {
    color: #00895f;
  }
  body.menu {
    /* 
----------
メニュー
----------
*/
    /* 一覧ページ */
    /* 個別ページ */
  }
  body.menu h1 {
    background-image: url("images/title_menu.jpg");
  }
  body.menu .menu__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  body.menu .menu__list li {
    width: 29.62264%;
    margin-bottom: 5.09434%;
    position: relative;
  }
  body.menu .menu__list li img {
    vertical-align: bottom;
  }
  body.menu .menu__list li .menu__list__text {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgba(0, 137, 95, 0.3);
    text-align: center;
    color: #ffffff;
  }
  body.menu .menu__list li.menu__list__none {
    margin-bottom: 0;
  }
  body.menu h2 {
    text-align: center;
    font-family: 'Noto Serif JP', serif;
    font-weight: 500;
    font-size: 30px;
    height: 110px;
    background-image: url("images/subtitle_menu.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 70px;
  }
  body.menu article section.menu__content {
    padding: 60px 0;
  }
  body.menu article section.menu__content h3 {
    font-size: 22px;
    line-height: 2em;
    font-family: 'Noto Serif JP', serif;
    font-weight: 500;
    color: #00895f;
    text-align: center;
    margin-bottom: 40px;
  }
  body.menu article section.menu__content .menu__content__inner {
    display: flex;
    justify-content: space-between;
  }
  body.menu article section.menu__content .menu__content__inner .menu__content__inner__image {
    width: 43.39623%;
    height: auto;
  }
  body.menu article section.menu__content .menu__content__inner .menu__content__inner__text {
    width: 52.83019%;
  }
  body.menu article section.menu__content:nth-child(even) {
    background-color: #e5f5f4;
  }
  body.menu article section.menu__content:nth-child(even) .menu__content__inner {
    flex-direction: row-reverse;
  }
  body.message {
    /* 
----------
院長挨拶
----------
*/
  }
  body.message .main {
    padding-bottom: 80px;
  }
  body.message h1 {
    background-image: url("images/title_message.jpg");
  }
  body.message h2 {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    line-height: 1;
    letter-spacing: 0.2em;
    color: #00895f;
    font-family: 'Noto Serif JP', serif;
    font-weight: 500;
    margin-bottom: 40px;
  }
  body.message h2:before, body.message h2:after {
    content: '';
    width: 72px;
    height: 1px;
    background-color: #00895f;
    margin: 0 12px;
  }
  body.message .message__profile .message__profile__content {
    display: flex;
    justify-content: space-between;
  }
  body.message .message__profile .message__profile__content .message__profile__content__image {
    width: 34.90566%;
  }
  body.message .message__profile .message__profile__content .message__profile__content__text {
    width: 61.32075%;
  }
  body.message .message__profile .message__profile__content .message__profile__content__text p:not(:first-child) {
    margin-top: 1em;
  }
  body.message .message__profile .message__profile__content .message__profile__content__text em {
    background: linear-gradient(transparent 60%, #f5f1d3 60%);
  }
  body.message .message__list {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
  }
  body.message .message__list .message__list__item {
    width: 48.11321%;
    background-color: #e5f5f4;
    border-radius: 10px;
    padding: 30px;
  }
  body.message .message__list .message__list__item h3 {
    text-align: center;
    font-family: 'Noto Serif JP', serif;
    font-weight: 500;
    font-size: 24px;
    margin-bottom: 16px;
  }
  body.message .message__list .message__list__item ul {
    margin: 1em 0;
  }
  body.message .message__list .message__list__item ul li {
    padding-left: 1.5em;
    text-indent: -1.5em;
  }
  body.message .message__list .message__list__item ul li:before {
    content: '•';
    display: inline-block;
    width: 1.5em;
    color: #61b6b1;
    text-indent: 0;
    text-align: center;
  }
  body.message .message__list .message__list__item ul li small {
    font-size: 14px;
    line-height: 1.71429;
  }
  body.message .message__photo__01 {
    display: flex;
    flex-wrap: wrap;
    margin-top: 90px;
  }
  body.message .message__photo__01 li {
    width: calc(100% / 3);
  }
  body.message .message__machine {
    margin-top: 90px;
  }
  body.message .message__machine .message__machine__01 {
    display: flex;
    justify-content: space-between;
  }
  body.message .message__machine .message__machine__01 li {
    width: 48.67925%;
  }
  body.message .message__machine .message__machine__02 {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
  }
  body.message .message__machine .message__machine__02 li {
    width: 31.69811%;
  }
  body.message .message__line {
    margin-top: 90px;
    text-align: center;
    border: 1px solid #61b6b1;
    padding: 40px;
  }
  body.message .message__line .message__line__title {
    font-family: 'Noto Serif JP', serif;
    font-weight: 500;
    font-size: 24px;
  }
  body.message .message__line .message__line__qr {
    margin: 16px auto;
    width: 100px;
  }
  body.access {
    /* 
----------
アクセス・料金
----------
*/
  }
  body.access .main {
    padding-bottom: 80px;
  }
  body.access h1 {
    background-image: url("images/title_access.jpg");
  }
  body.access h2 {
    text-align: center;
    margin-bottom: 40px;
  }
  body.access h2 span {
    display: block;
  }
  body.access h2 .head__title__en {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    line-height: 1;
    letter-spacing: 0.2em;
    color: #00895f;
    font-family: 'Noto Serif JP', serif;
    font-weight: 500;
    margin-bottom: 18px;
  }
  body.access h2 .head__title__en:before, body.access h2 .head__title__en:after {
    content: '';
    width: 72px;
    height: 1px;
    background-color: #00895f;
    margin: 0 12px;
  }
  body.access h2 .head__title__ja {
    font-size: 14px;
  }
  body.access .about .about__info {
    display: flex;
  }
  body.access .about .about__info .about__info__text {
    width: 62.26415%;
  }
  body.access .about .about__info .about__info__text h3 {
    font-family: 'Noto Serif JP', serif;
    font-weight: 500;
    font-size: 30px;
  }
  body.access .about .about__info .about__info__image {
    width: 35.09434%;
  }
  body.access .about .about__info .about__info__image img {
    margin-bottom: 16px;
  }
  body.access .about .about__map {
    margin-top: 10px;
  }
  body.access .price {
    margin: 90px auto 0;
  }
  body.access .price .inner--price {
    min-width: 800px;
    width: 800px;
  }
  body.access .price h3 {
    background-color: #e5f5f4;
    font-family: 'Noto Serif JP', serif;
    font-weight: 500;
    font-size: 24px;
    padding: 20px;
  }
  body.access .price table {
    margin-top: 0;
    margin-bottom: 40px;
    width: 100%;
  }
  body.access .price table td, body.access .price table th {
    border-top: 0;
    border-bottom: 1px solid #e5f5f4;
  }
  body.access .price table th {
    width: 8em;
  }
  body.access table {
    margin-top: 20px;
    border-collapse: collapse;
  }
  body.access table th, body.access table td {
    border-top: 1px solid #e5f5f4;
    padding: 1em;
    line-height: 1.5em;
  }
  body.access table th small, body.access table td small {
    font-size: 14px;
  }
  body.access table th {
    color: #61b6b1;
    font-weight: bold;
    text-align: center;
  }
}

/* SP */
@media screen and (max-width: 767px) {
  .forpc {
    display: none;
  }
  /*----------------------------------
共通レイアウト
-----------------------------------*/
  body {
    background-color: #ffffff;
    color: #282828;
    font-size: 14px;
    letter-spacing: 0.075em;
  }
  .inner {
    width: 94.66667%;
    margin-left: auto;
    margin-right: auto;
  }
  img {
    width: 100%;
    vertical-align: bottom;
  }
  a {
    transition: all 0.2s linear;
    color: #61b6b1;
    text-decoration: none;
  }
  a:hover {
    opacity: 0.6;
  }
  .main {
    padding: 30px 0;
  }
  header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    background-color: #61b6b1;
  }
  header .header__logo {
    width: 37.33333%;
    margin: 0 auto;
  }
  header .header__info {
    display: none;
  }
  header .header__info__tel {
    display: none;
  }
  header .header__info__btn {
    display: none;
  }
  header nav.gnav {
    width: 100%;
  }
  header nav.gnav ul {
    display: flex;
    border-top: 1px solid #e5f5f4;
  }
  header nav.gnav ul li {
    width: 20vw;
    height: 17.33333vw;
  }
  header nav.gnav ul li a {
    display: block;
    width: 100%;
    height: 100%;
    color: #ffffff;
    font-size: 2.66667vw;
    line-height: 1.1;
    text-align: center;
    letter-spacing: 0;
    padding-top: 12vw;
    background-position: center 2vw;
    background-repeat: no-repeat;
    background-size: 9.33333vw;
  }
  header nav.gnav ul li:not(:first-child) a {
    border-left: 1px solid #e5f5f4;
  }
  header nav.gnav ul li.gnav__home a {
    background-image: url("images/ico_home.png");
  }
  header nav.gnav ul li.gnav__message a {
    background-image: url("images/ico_message.png");
  }
  header nav.gnav ul li.gnav__menu a {
    background-image: url("images/ico_menu.png");
  }
  header nav.gnav ul li.gnav__access a {
    background-image: url("images/ico_access.png");
    letter-spacing: -0.2em;
  }
  header nav.gnav ul li.gnav__topics a {
    background-image: url("images/ico_topics.png");
  }
  header nav.header__mobileBanner {
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    width: 100%;
  }
  header nav.header__mobileBanner .header__mobileBanner__tel {
    background-color: #eb860f;
    width: 50%;
    padding-bottom: env(safe-area-inset-bottom);
    padding-bottom: constant(safe-area-inset-bottom);
  }
  header nav.header__mobileBanner .header__mobileBanner__tel a {
    display: flex;
    width: 100%;
    height: 100%;
    color: #ffffff;
    justify-content: center;
    align-items: center;
    font-family: 'Noto Serif JP', serif;
    font-weight: 500;
    font-size: 16px;
    padding: 10px 0;
  }
  header nav.header__mobileBanner .header__mobileBanner__tel a .header__mobileBanner__tel__ico {
    display: block;
    width: 16px;
    height: 20px;
    margin-right: 4px;
  }
  header nav.header__mobileBanner .header__mobileBanner__tel a .header__mobileBanner__tel__ico svg path {
    fill: #ffffff;
  }
  header nav.header__mobileBanner .header__mobileBanner__line {
    background-color: #00b900;
    width: 50%;
    padding-bottom: env(safe-area-inset-bottom);
    padding-bottom: constant(safe-area-inset-bottom);
  }
  header nav.header__mobileBanner .header__mobileBanner__line a {
    display: flex;
    width: 100%;
    height: 100%;
    color: #ffffff;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    padding: 10px 0;
  }
  header nav.header__mobileBanner .header__mobileBanner__line a .header__mobileBanner__line__ico {
    display: block;
    width: 22px;
    margin-right: 4px;
  }
  footer {
    background-color: #e5f5f4;
    padding: 20px 0;
  }
  footer .footer__gnav ul {
    letter-spacing: -.40em;
    word-spacing: 0;
    font-size: 12px;
    line-height: 1.2;
    text-align: center;
  }
  footer .footer__gnav ul li {
    letter-spacing: 0;
    display: inline-block;
    margin: 0.4em 0;
  }
  footer .footer__gnav ul li a {
    color: #282828;
    display: block;
    padding: 0 14px;
    border-left: 1px solid #61b6b1;
  }
  footer .footer__gnav ul li:last-child a {
    border-right: 1px solid #61b6b1;
  }
  footer .footer__copyright {
    text-align: center;
    font-size: 10px;
    margin-top: 20px;
  }
  footer .footer__copyright .footer__copyright__logo img {
    width: 120px;
  }
  /*----------------------------------
パーツ
-----------------------------------*/
  a.btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 280px;
    height: 40px;
    position: relative;
    background-color: #61b6b1;
    margin: 0 auto;
    color: #ffffff;
    letter-spacing: 0;
  }
  a.btn .btn__arrow {
    display: block;
    position: absolute;
    width: 6px;
    height: 10px;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    line-height: 1;
  }
  a.btn .btn__arrow svg {
    width: 100%;
    height: 100%;
    vertical-align: top;
  }
  a.btn .btn__arrow svg path {
    fill: #ffffff;
  }
  a.btn.btn--white {
    background-color: #ffffff;
    color: #00895f;
  }
  a.btn.btn--white svg path {
    fill: #00895f;
  }
  body.home {
    /* 
----------
HOME
----------
*/
  }
  body.home section {
    padding: 40px 0;
  }
  body.home h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  body.home h2 span {
    display: block;
  }
  body.home h2 .head__title__en {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    line-height: 1;
    letter-spacing: 0.2em;
    color: #00895f;
    font-family: 'Noto Serif JP', serif;
    font-weight: 500;
    margin-bottom: 10px;
  }
  body.home h2 .head__title__en:before, body.home h2 .head__title__en:after {
    content: '';
    width: 62px;
    height: 1px;
    background-color: #00895f;
    margin: 0 12px;
  }
  body.home h2 .head__title__ja {
    font-size: 12px;
  }
  body.home .kv {
    width: 100%;
    height: 100vh;
    background-image: url("images/kv_sp.jpg");
    position: relative;
    background-size: cover;
    background-position: center;
    padding: 0;
  }
  body.home .kv h1 {
    position: absolute;
    top: 50%;
    right: 1.33333vw;
    transform: translateY(-50%);
    width: 56.53333%;
  }
  body.home .kv .kv__advantage__wrapper {
    position: absolute;
    bottom: 7.46667vw;
    left: 0;
    width: 100%;
  }
  body.home .kv .kv__advantage__wrapper .kv__advantage {
    margin-left: auto;
    margin-right: auto;
    padding-left: 2.66667%;
    padding-right: 2.66667%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
  body.home .kv .kv__advantage__wrapper .kv__advantage li {
    width: 49.29577%;
    height: 12vw;
    display: flex;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8);
    margin-top: 1.40845%;
  }
  body.home .kv .kv__advantage__wrapper .kv__advantage li .kv__advantage__image {
    width: 28%;
    text-align: center;
  }
  body.home .kv .kv__advantage__wrapper .kv__advantage li .kv__advantage__image img {
    width: 75.67568%;
  }
  body.home .kv .kv__advantage__wrapper .kv__advantage li .kv__advantage__text {
    width: 68%;
    font-size: 3.46667vw;
    line-height: 4.8vw;
    font-weight: bold;
    letter-spacing: 0;
  }
}

@media screen and (max-width: 767px) and (min-width: 1100px) {
  body.home .kv .kv__advantage__wrapper .kv__advantage li .kv__advantage__text {
    font-size: 18px;
    line-height: 28px;
  }
}

@media screen and (max-width: 767px) {
  body.home .kv .kv__scroll {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 9.01408%;
    transform: translate(-50%, 50%);
  }
  body.home .message .message__image {
    width: 70.42254%;
    margin: 0 auto 20px;
  }
  body.home .feature {
    background-color: #e5f5f4;
  }
  body.home .feature .feature__list li {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    color: #ffffff;
    padding: 20px 0;
    min-height: 375px;
  }
  body.home .feature .feature__list li:not(:first-child) {
    margin-top: 20px;
  }
  body.home .feature .feature__list li.feature__list__item--01 {
    background-image: url("images/bg_feature_01_sp.jpg");
  }
  body.home .feature .feature__list li.feature__list__item--02 {
    background-image: url("images/bg_feature_02_sp.jpg");
  }
  body.home .feature .feature__list li.feature__list__item--03 {
    background-image: url("images/bg_feature_03_sp.jpg");
  }
  body.home .feature .feature__list li.feature__list__item--04 {
    background-image: url("images/bg_feature_04_sp.jpg");
  }
  body.home .feature .feature__list li.feature__list__item--05 {
    background-image: url("images/bg_feature_05_sp.jpg");
  }
  body.home .feature .feature__list li .feature__list__item__inner {
    width: 90.14085%;
  }
  body.home .feature .feature__list li .feature__list__item__inner h3 {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    flex-direction: column;
  }
  body.home .feature .feature__list li .feature__list__item__inner h3 .feature__head__no {
    display: block;
    width: 14.08451%;
    margin-bottom: 10px;
  }
  body.home .feature .feature__list li .feature__list__item__inner h3 .feature__head__text {
    font-family: 'Noto Serif JP', serif;
    font-weight: 500;
    display: block;
    font-size: 16px;
    line-height: 1.25em;
    text-align: center;
  }
  body.home .feature .feature__list li .feature__content small {
    font-size: 12px;
  }
  body.home .trigger .trigger__content .trigger__content__image {
    display: flex;
  }
  body.home .trigger .trigger__content .trigger__content__text .trigger__content__text__head {
    background-image: url("images/bg_trigger_sp.jpg");
    background-position: center;
    background-size: cover;
    padding: 60px 0;
  }
  body.home .trigger .trigger__content .trigger__content__text h3 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 58.66667vw;
    height: 58.66667vw;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 29.33333vw;
    margin: 0 auto;
    font-family: 'Noto Serif JP', serif;
    font-weight: 500;
    color: #00895f;
    font-size: 4.8vw;
  }
  body.home .trigger .trigger__content .trigger__content__text h3 .trigger__content__text__x {
    width: 13.33333vw;
  }
  body.home .trigger .trigger__content .trigger__content__text h3 span:nth-child(2) {
    margin: 10px 0;
  }
  body.home .trigger .trigger__content .trigger__content__text .trigger__content__text__detail {
    background-color: #00895f;
    margin: 0 auto 1em;
    padding: 16px;
    color: #ffffff;
  }
  body.home .trigger .trigger__content .trigger__content__text .trigger__content__text__detail p {
    margin-bottom: 14px;
  }
  body.home .menu {
    background-image: url("images/bg_menu.jpg");
    background-position: center;
    background-size: cover;
  }
  body.home .menu .menu__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: -5.09434%;
  }
  body.home .menu .menu__list li {
    width: 48.59155%;
    margin-bottom: 1.88679%;
    position: relative;
    box-shadow: 0px 0px 15.36px 0.64px rgba(10, 141, 99, 0.1);
  }
  body.home .menu .menu__list li img {
    vertical-align: bottom;
  }
  body.home .menu .menu__list li .menu__list__text {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgba(0, 137, 95, 0.3);
    text-align: center;
    color: #ffffff;
  }
  body.home .menu .menu__list li.menu__list__none {
    margin-bottom: 0;
  }
  body.home .topics .archive__list {
    margin-bottom: 30px;
  }
  body.home .topics .archive__list li {
    background-color: #e5f5f4;
    padding: 20px 12px;
    border-radius: 4px;
    margin: 12px 0;
  }
  body.home .topics .archive__list li a:hover {
    opacity: 1;
  }
  body.home .topics .archive__list li a:hover .archive__list__more {
    background-color: #00895f;
    color: #ffffff;
  }
  body.home .topics .archive__list li a:hover .archive__list__more svg path {
    fill: #ffffff;
  }
  body.home .topics .archive__list li .archive__list__date {
    font-size: 12px;
    color: #e63f3f;
    line-height: 1;
    margin-bottom: 12px;
  }
  body.home .topics .archive__list li .archive__list__title {
    color: #00895f;
    line-height: 1.71429em;
    margin-bottom: 12px;
    font-weight: bold;
  }
  body.home .topics .archive__list li .archive__list__more {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 106px;
    height: 30px;
    position: relative;
    background-color: #ffffff;
    margin: 0 auto;
    color: #00895f;
    transition: all 0.2s linear;
    border-radius: 15px;
    font-family: 'Noto Serif JP', serif;
    font-weight: 500;
    font-size: 12px;
  }
  body.home .topics .archive__list li .archive__list__more .archive__list__more__arrow {
    display: block;
    position: absolute;
    width: 6px;
    height: 10px;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    line-height: 1;
  }
  body.home .topics .archive__list li .archive__list__more .archive__list__more__arrow svg {
    width: 100%;
    height: 100%;
    vertical-align: top;
  }
  body.home .topics .archive__list li .archive__list__more .archive__list__more__arrow svg path {
    fill: #00895f;
  }
  body.home .about .about__info .about__info__text h3 {
    font-family: 'Noto Serif JP', serif;
    font-weight: 500;
    font-size: 20px;
    text-align: center;
  }
  body.home .about .about__info .about__info__text table {
    width: 100%;
    margin-top: 10px;
    border-collapse: collapse;
  }
  body.home .about .about__info .about__info__text table th, body.home .about .about__info .about__info__text table td {
    border-top: 1px solid #e5f5f4;
    padding: 1em;
    line-height: 1.5em;
  }
  body.home .about .about__info .about__info__text table th small, body.home .about .about__info .about__info__text table td small {
    font-size: 12px;
  }
  body.home .about .about__info .about__info__text table th {
    color: #61b6b1;
    font-weight: bold;
    text-align: center;
    width: 7em;
  }
  body.home .about .about__info .about__info__image img {
    margin-bottom: 8px;
  }
  body.home .about .about__map {
    margin-top: 10px;
  }
  body.home .blog {
    padding-top: 0;
  }
  body.home .blog .inner {
    position: relative;
  }
  body.home .blog .inner h3 {
    position: absolute;
    top: 50%;
    left: -1.33333vw;
    transform: translateY(-50%);
    background-color: #00895f;
    color: #ffffff;
    font-family: 'Noto Serif JP', serif;
    font-weight: 500;
    text-align: center;
    width: 207px;
    padding: 9px 0;
    font-size: 20px;
  }
  body.home .blog .inner h3 small {
    font-size: 14px;
    margin-left: 20px;
  }
  body.page h1, body.post h1, body.single h1, body.archive h1 {
    background-size: cover;
    background-position: center;
    height: 180px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 29.33333vw;
  }
  body.page h1 span, body.post h1 span, body.single h1 span, body.archive h1 span {
    display: block;
  }
  body.page h1 .pagetitle, body.post h1 .pagetitle, body.single h1 .pagetitle, body.archive h1 .pagetitle {
    color: #00895f;
    font-family: 'Noto Serif JP', serif;
    font-weight: 500;
    font-size: 24px;
    letter-spacing: 0.2em;
  }
  body.page h1 .pagetitle__sub, body.post h1 .pagetitle__sub, body.single h1 .pagetitle__sub, body.archive h1 .pagetitle__sub {
    font-size: 12px;
    letter-spacing: 0.2em;
  }
  body.page .path, body.post .path, body.single .path, body.archive .path {
    font-size: 10px;
    letter-spacing: -.40em;
    word-spacing: 0;
    padding: 10px 0;
  }
  body.page .path li, body.post .path li, body.single .path li, body.archive .path li {
    display: inline;
    letter-spacing: 0;
  }
  body.page .path li:not(:first-child), body.post .path li:not(:first-child), body.single .path li:not(:first-child), body.archive .path li:not(:first-child) {
    background-image: url("images/arrow.svg");
    background-size: 4px;
    background-repeat: no-repeat;
    background-position: center left;
    padding-left: 14px;
    margin-left: 10px;
  }
  body.archive {
    /* 
----------
アーカイブ
----------
*/
  }
  body.archive h1 {
    background-image: url("images/title_topics.jpg");
  }
  body.archive .main--archive {
    margin-bottom: 80px;
  }
  body.archive .archive__list {
    margin-bottom: 30px;
  }
  body.archive .archive__list li {
    background-color: #e5f5f4;
    padding: 20px 12px;
    border-radius: 4px;
    margin: 12px 0;
  }
  body.archive .archive__list li a:hover {
    opacity: 1;
  }
  body.archive .archive__list li a:hover .archive__list__more {
    background-color: #00895f;
    color: #ffffff;
  }
  body.archive .archive__list li a:hover .archive__list__more svg path {
    fill: #ffffff;
  }
  body.archive .archive__list li .archive__list__date {
    font-size: 12px;
    color: #e63f3f;
    line-height: 1;
    margin-bottom: 12px;
  }
  body.archive .archive__list li .archive__list__title {
    color: #00895f;
    line-height: 1.71429em;
    margin-bottom: 12px;
    font-weight: bold;
  }
  body.archive .archive__list li .archive__list__more {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 106px;
    height: 30px;
    position: relative;
    background-color: #ffffff;
    margin: 0 auto;
    color: #00895f;
    transition: all 0.2s linear;
    border-radius: 15px;
    font-family: 'Noto Serif JP', serif;
    font-weight: 500;
    font-size: 12px;
  }
  body.archive .archive__list li .archive__list__more .archive__list__more__arrow {
    display: block;
    position: absolute;
    width: 6px;
    height: 10px;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    line-height: 1;
  }
  body.archive .archive__list li .archive__list__more .archive__list__more__arrow svg {
    width: 100%;
    height: 100%;
    vertical-align: top;
  }
  body.archive .archive__list li .archive__list__more .archive__list__more__arrow svg path {
    fill: #00895f;
  }
  body.archive .wp-pagenavi {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
  }
  body.archive .wp-pagenavi .current, body.archive .wp-pagenavi .page {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 26px;
    height: 26px;
    font-family: 'Noto Serif JP', serif;
    font-weight: 500;
    border: 1px solid #61b6b1;
    margin: 0 5px;
  }
  body.archive .wp-pagenavi .current {
    color: #61b6b1;
    background-color: #e5f5f4;
  }
  body.archive .wp-pagenavi .nextpostslink {
    font-family: 'Noto Serif JP', serif;
    font-weight: 500;
    position: relative;
    padding-right: 14px;
    margin-left: 10px;
  }
  body.archive .wp-pagenavi .nextpostslink:before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    z-index: 2;
    width: 6px;
    height: 10px;
    transform: translateY(-50%);
    background-image: url("images/arrow_green.svg");
    background-repeat: no-repeat;
    background-size: contain;
  }
  body.archive .wp-pagenavi .previouspostslink {
    font-family: 'Noto Serif JP', serif;
    font-weight: 500;
    position: relative;
    padding-left: 14px;
    margin-right: 10px;
  }
  body.archive .wp-pagenavi .previouspostslink:before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    z-index: 2;
    width: 6px;
    height: 10px;
    transform: translateY(-50%) scale(-1, 1);
    background-image: url("images/arrow_green.svg");
    background-repeat: no-repeat;
    background-size: contain;
  }
  body.post, body.single {
    /* 
----------
投稿
----------
*/
  }
  body.post h1, body.single h1 {
    background-image: url("images/title_topics.jpg");
  }
  body.post .main--single, body.single .main--single {
    margin-bottom: 40px;
  }
  body.post article.content .content__date, body.single article.content .content__date {
    font-size: 14px;
    color: #e63f3f;
    margin-bottom: 14px;
  }
  body.post article.content h2, body.single article.content h2 {
    font-size: 20px;
    line-height: 24px;
    color: #00895f;
    margin-bottom: 14px;
    font-weight: bold;
  }
  body.post article.content .content__categories ul li, body.single article.content .content__categories ul li {
    display: inline-block;
    color: #61b6b1;
    font-size: 14px;
    margin-right: 1em;
  }
  body.post article.content .content__categories ul li:before, body.single article.content .content__categories ul li:before {
    content: '#';
    display: inline;
  }
  body.post article.content .content__body, body.single article.content .content__body {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  body.post article.content .content__body p, body.single article.content .content__body p {
    margin: 1em 0;
  }
  body.post article.content .content__body ul, body.single article.content .content__body ul {
    margin: 1em 0 1em 1em;
  }
  body.post article.content .content__body ul li, body.single article.content .content__body ul li {
    padding-left: 1.5em;
    text-indent: -1.5em;
  }
  body.post article.content .content__body ul li:before, body.single article.content .content__body ul li:before {
    content: '•';
    display: inline-block;
    width: 1.5em;
    color: #61b6b1;
    text-indent: 0;
    text-align: center;
  }
  body.post article.content .content__body ol, body.single article.content .content__body ol {
    counter-reset: item;
    margin: 1em 0 1em 1em;
  }
  body.post article.content .content__body ol li, body.single article.content .content__body ol li {
    padding-left: 1.5em;
    text-indent: -1.5em;
  }
  body.post article.content .content__body ol li:before, body.single article.content .content__body ol li:before {
    counter-increment: item;
    content: counter(item) ".";
    color: #61b6b1;
    display: inline-block;
    width: 1.5em;
    text-indent: 0;
  }
  body.post article.content .content__body blockquote, body.single article.content .content__body blockquote {
    margin: 1em 0 1em 2em;
    padding-left: 1em;
    border-left: 6px solid #61b6b1;
  }
  body.post article.content .content__body table, body.single article.content .content__body table {
    border-collapse: collapse;
    margin: 1em 0;
  }
  body.post article.content .content__body table th, body.post article.content .content__body table td, body.single article.content .content__body table th, body.single article.content .content__body table td {
    border: 1px solid #61b6b1;
    padding: 10px;
  }
  body.post article.content .content__body table th, body.single article.content .content__body table th {
    background-color: #e5f5f4;
  }
  body.post aside, body.single aside {
    margin-top: 60px;
  }
  body.post aside h2, body.single aside h2 {
    background-color: #e5f5f4;
    padding: 14px;
    text-align: center;
  }
  body.post aside h2 span, body.single aside h2 span {
    display: block;
  }
  body.post aside h2 span.sidebar__title, body.single aside h2 span.sidebar__title {
    font-family: 'Noto Serif JP', serif;
    font-weight: 500;
    color: #00895f;
    font-size: 20px;
    line-height: 1.4;
  }
  body.post aside h2 span.sidebar__title__sub, body.single aside h2 span.sidebar__title__sub {
    font-family: 'Noto Serif JP', serif;
    font-weight: 500;
    color: #282828;
    font-size: 14px;
    line-height: 1.2;
  }
  body.post aside nav .sidebar__list li, body.single aside nav .sidebar__list li {
    padding: 16px 0;
    border-bottom: 1px solid #e5f5f4;
  }
  body.post aside nav .sidebar__list li .sidebar__list__date, body.single aside nav .sidebar__list li .sidebar__list__date {
    color: #e63f3f;
    font-size: 12px;
    margin-bottom: 10px;
  }
  body.post aside nav .sidebar__list li .sidebar__list__title, body.single aside nav .sidebar__list li .sidebar__list__title {
    font-size: 14px;
    font-weight: bold;
    line-height: 24px;
  }
  body.post aside nav .sidebar__list li .sidebar__list__title a, body.single aside nav .sidebar__list li .sidebar__list__title a {
    color: #00895f;
  }
  body.menu {
    /* 個別ページ */
  }
  body.menu h1 {
    background-image: url("images/title_menu.jpg");
  }
  body.menu .menu__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  body.menu .menu__list li {
    width: 48.59155%;
    margin-bottom: 2.8169%;
    position: relative;
  }
  body.menu .menu__list li img {
    vertical-align: bottom;
  }
  body.menu .menu__list li .menu__list__text {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgba(0, 137, 95, 0.3);
    text-align: center;
    color: #ffffff;
  }
  body.menu .menu__list li.menu__list__none {
    margin-bottom: 0;
  }
  body.menu h2 {
    text-align: center;
    font-family: 'Noto Serif JP', serif;
    font-weight: 500;
    font-size: 20px;
    height: 80px;
    background-image: url("images/subtitle_menu.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
  }
  body.menu article section.menu__content {
    padding: 30px 0;
  }
  body.menu article section.menu__content h3 {
    font-size: 18px;
    line-height: 1.27273em;
    font-family: 'Noto Serif JP', serif;
    font-weight: 500;
    color: #00895f;
    text-align: center;
    margin-bottom: 20px;
  }
  body.menu article section.menu__content .menu__content__inner .menu__content__inner__image {
    height: auto;
    margin-bottom: 20px;
  }
  body.menu article section.menu__content:nth-child(even) {
    background-color: #e5f5f4;
  }
  body.menu article section.menu__content:nth-child(even) .menu__content__inner {
    flex-direction: row-reverse;
  }
  body.message {
    /* 
----------
院長挨拶
----------
*/
  }
  body.message .main {
    padding-bottom: 80px;
  }
  body.message h1 {
    background-image: url("images/title_message.jpg");
  }
  body.message h2 {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    line-height: 1;
    letter-spacing: 0.2em;
    color: #00895f;
    font-family: 'Noto Serif JP', serif;
    font-weight: 500;
    margin-bottom: 20px;
  }
  body.message h2:before, body.message h2:after {
    content: '';
    width: 32px;
    height: 1px;
    background-color: #00895f;
    margin: 0 12px;
  }
  body.message .message__profile .message__profile__content .message__profile__content__image {
    width: 80%;
    margin: 0 auto 20px;
  }
  body.message .message__profile .message__profile__content .message__profile__content__text p:not(:first-child) {
    margin-top: 1em;
  }
  body.message .message__profile .message__profile__content .message__profile__content__text em {
    background: linear-gradient(transparent 60%, #f5f1d3 60%);
  }
  body.message .message__list {
    margin-top: 40px;
  }
  body.message .message__list .message__list__item {
    background-color: #e5f5f4;
    border-radius: 10px;
    padding: 14px;
    margin-bottom: 14px;
  }
  body.message .message__list .message__list__item h3 {
    text-align: center;
    font-family: 'Noto Serif JP', serif;
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 10px;
  }
  body.message .message__list .message__list__item ul {
    margin: 1em 0;
  }
  body.message .message__list .message__list__item ul li {
    padding-left: 1.5em;
    text-indent: -1.5em;
  }
  body.message .message__list .message__list__item ul li:before {
    content: '•';
    display: inline-block;
    width: 1.5em;
    color: #61b6b1;
    text-indent: 0;
    text-align: center;
  }
  body.message .message__list .message__list__item ul li small {
    font-size: 12px;
    line-height: 1.71429;
  }
  body.message .message__photo__01 {
    display: flex;
    flex-wrap: wrap;
    margin-top: 40px;
  }
  body.message .message__photo__01 li {
    width: 50%;
  }
  body.message .message__machine {
    margin-top: 40px;
  }
  body.message .message__machine .message__machine__01 {
    display: flex;
    justify-content: space-between;
  }
  body.message .message__machine .message__machine__01 li {
    width: 48.67925%;
  }
  body.message .message__machine .message__machine__02 {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  body.message .message__machine .message__machine__02 li {
    width: 31.69811%;
  }
  body.message .message__line {
    margin-top: 40px;
    text-align: center;
    border: 1px solid #61b6b1;
    padding: 20px;
  }
  body.message .message__line .message__line__title {
    font-family: 'Noto Serif JP', serif;
    font-weight: 500;
    font-size: 18px;
  }
  body.message .message__line .message__line__qr {
    margin: 14px auto;
    width: 100px;
  }
  body.access {
    /* 
----------
アクセス・料金
----------
*/
  }
  body.access .main {
    padding-bottom: 40px;
  }
  body.access h1 {
    background-image: url("images/title_access.jpg");
  }
  body.access h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  body.access h2 span {
    display: block;
  }
  body.access h2 .head__title__en {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    line-height: 1;
    letter-spacing: 0.2em;
    color: #00895f;
    font-family: 'Noto Serif JP', serif;
    font-weight: 500;
    margin-bottom: 10px;
  }
  body.access h2 .head__title__en:before, body.access h2 .head__title__en:after {
    content: '';
    width: 62px;
    height: 1px;
    background-color: #00895f;
    margin: 0 12px;
  }
  body.access h2 .head__title__ja {
    font-size: 12px;
  }
  body.access .about .about__info .about__info__text h3 {
    font-family: 'Noto Serif JP', serif;
    font-weight: 500;
    font-size: 20px;
    text-align: center;
  }
  body.access .about .about__info .about__info__text table {
    width: 100%;
    margin-top: 10px;
    border-collapse: collapse;
  }
  body.access .about .about__info .about__info__text table th, body.access .about .about__info .about__info__text table td {
    border-top: 1px solid #e5f5f4;
    padding: 1em;
    line-height: 1.5em;
  }
  body.access .about .about__info .about__info__text table th small, body.access .about .about__info .about__info__text table td small {
    font-size: 12px;
  }
  body.access .about .about__info .about__info__text table th {
    color: #61b6b1;
    font-weight: bold;
    text-align: center;
    width: 7em;
  }
  body.access .about .about__info .about__info__image img {
    margin-bottom: 8px;
  }
  body.access .about .about__map {
    margin-top: 10px;
  }
  body.access .price {
    margin: 40px auto 0;
  }
  body.access .price h3 {
    background-color: #e5f5f4;
    font-family: 'Noto Serif JP', serif;
    font-weight: 500;
    font-size: 18px;
    padding: 10px;
  }
  body.access .price table {
    margin-top: 0;
    margin-bottom: 20px;
    width: 100%;
  }
  body.access .price table td, body.access .price table th {
    border-top: 0;
    border-bottom: 1px solid #e5f5f4;
  }
  body.access .price table th {
    width: 8em;
  }
  body.access table {
    margin-top: 20px;
    border-collapse: collapse;
  }
  body.access table th, body.access table td {
    border-top: 1px solid #e5f5f4;
    padding: 1em;
    line-height: 1.5em;
  }
  body.access table th small, body.access table td small {
    font-size: 14px;
  }
  body.access table th {
    color: #61b6b1;
    font-weight: bold;
    text-align: center;
  }
}

/* anim */
.animElem.slideIn {
  transform: translateY(50px);
  opacity: 0;
}

.slideIn {
  transition: all 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.animElem.slideToLeft {
  transform: translateX(50px);
  opacity: 0;
}

.slideToLeft {
  transition: all 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.animElem.slideToRight {
  transform: translateX(-50px);
  opacity: 0;
}

.slideToRight {
  transition: all 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.animElem.scale {
  transform: scale(0.8);
  opacity: 0;
}

.scale {
  transition: all 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.animElem.fadeIn {
  opacity: 0;
}

.fadeIn {
  transition: all 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);
}
